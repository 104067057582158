import { useEffect } from 'react';
import { EFeatureFlag } from 'constants/featureFlags';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useBoolFlagAssignment } from 'components/feature-flag/hooks/useFlagAssignment';
import { PageLoader } from 'components/loaders/PageLoader';
import { SEEN_WELCOME_PAGE_STORAGE_KEY } from 'components/teams/constants';
import { useLocalstorage } from 'hooks/useLocalstorage';
import { useUser } from 'hooks/useUser';

/**
 * We don't currently have a home page; we redirect to either /experiments or /getting-started.
 */
export default function HomePage() {
  const router = useRouter();
  const {
    currentCompany: company,
    teams
  } = useUser();
  const showTeamsPickerInterstitial = useBoolFlagAssignment(EFeatureFlag.ShowTeamsPickerInterstitial);
  const {
    getItem
  } = useLocalstorage();
  let seenWelcomePage = false;
  if (typeof window !== 'undefined') {
    // runs in browser only
    seenWelcomePage = !!getItem(SEEN_WELCOME_PAGE_STORAGE_KEY);
  }
  useEffect(() => {
    // check for database connection
    if (!company.databaseConnections?.length) {
      console.debug('Company with no warehouse connection; redirecting to getting started page.');
      router.push('/getting-started');
      return;
    }
    if (showTeamsPickerInterstitial && teams.length === 0 && !seenWelcomePage) {
      router.push('/join-teams').catch(e => console.error('failed to navigate', e));
      return;
    }
    console.debug('Company with warehouse connection; redirecting to experiments page.');
    router.push('/experiments').catch(e => console.error('failed to navigate', e));
  }, [company, router, teams, showTeamsPickerInterstitial, seenWelcomePage]);
  return <>
      <Head>
        <title>Eppo</title>
      </Head>
      <PageLoader />
    </>;
}